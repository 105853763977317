import React from "react";
import ContentQuery from "Content/ContentQuery.js";
import dContentQuery from "Dispatchers/dContentQuery.js";
import qs from "query-string";
import {v4 as uuid} from "uuid";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/**
 * `withContentQuery()` HOC wrapper to wrap a component with `contentQuery`
 *
 * @param {ReactComponent} Component Component to wrap
 * @return {ReactComponent} Wrapped component - see source for details
 */
const withContentQuery = Component => {

	/**
	 * Class wrapping a React component with `ComponentWithContentQuery`
	 *
	 * @package BrandTracker
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright BrandTracker
	 */
	const cmcq = class ComponentWithContentQuery extends React.PureComponent {

		/**
		 * Constructor.
		 *
		 * @param {Object} props
		 * @return {self}
		 */
		constructor(props) {
			super(props);

			/**
			 * State
			 * 
			 * @type {Object}
			 */
			this.state = {

				/**
				 * Query
				 *
				 * @type {ContentQuery}
				 */
				query: this.query

			};

			/**
			 * Method binds
			 */
			this.updateContentQuery = this.updateContentQuery.bind(this);
			this.updateContentQueryAndResetOffset = this.updateContentQueryAndResetOffset.bind(this);

		}


		/**
		 * Compoennt updated.
		 *
		 * @param {Object} prevProps
		 * @return {void}
		 */
		componentDidUpdate(prevProps) {

			const id = (this.queryId !== qs.parse(prevProps.location.search).query);
			const query = (this.props.contentQuery !== prevProps.contentQuery);

			if ((query && !this.queryId) || (id && this.queryId)) {
				this.setState({query: this.query});
			}

		}


		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					contentQuery={this.state.query}
					contentQueryId={this.queryId}
					contentQueryIdValid={this.queryIdValid}
					updateContentQuery={this.updateContentQuery}
					updateContentQueryAndResetOffset={this.updateContentQueryAndResetOffset} />
			);
		}


		/**
		 * Update the active content query in the state.
		 *
		 * This merges in the object given as `obj`.
		 *
		 * @param {Object} obj
		 * @param {Boolean} queryId optional Generate new query ID
		 * @return {Object} Content query
		 */
		updateContentQuery(obj, queryId=null) {

			const query = new ContentQuery(this.props.contentQuery);

			Object.assign(query, obj);
			dContentQuery(query, (queryId ? uuid() : this.queryId));
			return query;

		}


		/**
		 * Variation of `updateContentQuery()` that automatically 
		 * resets the pagination offset to `0`.
		 *
		 * @param {Object} obj
		 * @param {Boolean} queryId optional Generate new query ID
		 * @return {Object} Content query
		 */
		updateContentQueryAndResetOffset(obj, queryId=null) {
			return this.updateContentQuery({...obj, offset: 0}, queryId);
		}


		/**
		 * Get our query object.
		 * 
		 * @return {ContentQuery}
		 */
		get query() {
			const query = this.props.contentQueries[this.queryId];
			return new ContentQuery((query || this.props.contentQuery));
		}


		/**
		 * Get our content query ID.
		 * 
		 * @return {String|undefined}
		 */
		get queryId() {
			return qs.parse(this.props.location.search).query;
		}


		/**
		 * Get whether our content query ID is valid.
		 * 
		 * @return {Boolean}
		 */
		get queryIdValid() {
			return !!this.props.contentQueries[this.queryId];
		}

	};

	return connect(({contentQuery, contentQueries}) => ({contentQuery, contentQueries}))(withRouter(cmcq));

};

export default withContentQuery;

import Reducer from "./Reducer.js";
import State from "./State.js";
import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {persistStore, persistReducer} from "redux-persist";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";
import storage from "redux-persist/lib/storage";

/**
 * `redux-persist`
 */
const rpc = {
	storage,
	key: "brt",
	blacklist: ["drawer", "f12", "rkey"]
};

/**
 * `redux-state-sync`
 */
const rss = {
	blacklist: [
		"contentQuery",
		"drawer",
		"f12",
		"rkey",
		"persist/PERSIST",
		"persist/REHYDRATE"
	],
	broadcastChannelOption: {webWorkerSupport: false}
};


/**
 * Create store
 */
const store = createStore(
	persistReducer(rpc, Reducer.reduce),
	State,
	composeWithDevTools(applyMiddleware(createStateSyncMiddleware(rss)))
);

/**
 * Create store perisistor
 */
const persistor = persistStore(store);

/**
 * Initialise sync listener
 */
initMessageListener(store);

export default store;
export {store as Store, persistor as StorePersistor};
